<template>
  <div class="cookies-policy">
    <div class="cookies-policy__wrapper">
      <div class="cookies-policy__content">
        <vue-markdown>
          {{content}}
        </vue-markdown>
      </div>
    </div>
  </div>
</template>

<script>
import VueMarkdown from 'vue-markdown'

export default {
  name: "CookiesPolicy",
  metaInfo: {
    title: 'Freely',
    titleTemplate: '%s | Cookies Policy'
  },
  data () {
    return {
      content:''
    }
  },
  components: {
    VueMarkdown
  },
  created(){
    this.content = `\r${this.$i18n.t('markdown.cookies_policy')}`
  },
  mounted() {
    this.$scrollTo('.header.v-toolbar');
  },
};
</script>

<style lang="scss">
@mixin r($width) {
  @media only screen and (max-width: "#{$width}px") {
    @content;
  }
}

.cookies-policy {
  &__wrapper {
    max-width: 1580px;
    margin: 0 auto;
    padding: 0 24px;

    @include r(1700) {
      max-width: 1440px;
    }

    @include r(1500) {
      max-width: 1240px;
    }

    @include r(1400) {
      max-width: 1140px;
    }
  }

  &__content {
    padding: 150px 0 220px 0;

    @include r(1700) {
      padding: 120px 0 200px 0;
    }
    @include r(1500) {
      padding: 70px 0 160px 0;
    }
    @include r(1000) {
      padding: 50px 0 100px 0;
    }

    @include r(700) {
      padding: 50px 0 70px 0;
    }
  }
}
</style>
